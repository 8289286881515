<template>
  <div>
    <Header></Header>
    <div class="outer_box content_box">
      <div class="select_type">
        <el-radio-group class="select_type_el" v-model="selectType" @change="getMovieList(1)">
          <el-radio label="0" border>Movie</el-radio>
          <el-radio label="1" border>MV</el-radio>
        </el-radio-group>
      </div>
      <template v-if="selectType === '0'">
        <el-tabs class="tab_box" v-model="classifyActive" @tab-click="getMovieList(1)">
          <el-tab-pane v-for="(item,index) in classifyList" :key=index :label="item">
          </el-tab-pane>
        </el-tabs>
      </template>
      <template v-else>
        <el-tabs class="tab_box" v-model="mvClassifyActive" @tab-click="getMovieList(1)">
          <el-tab-pane v-for="(item,index) in mvClassifyList" :key=index :label="item">
          </el-tab-pane>
        </el-tabs>
      </template>
      <div class="movie">
        <ul class="movie_list">
          <li class="movie_list_item" v-for="(item,index) in movieList" :key=index @click="toDetail(item)">
            <div class="movie_list_item_box">
              <div class="img_box">
                <img :src="$ipfs_url+item.title_page" alt="">
              </div>
              <div class="movie_name">
                {{item.title}}
              </div>
              <div class="movie_desc ellipsis">
                {{item.description}}
              </div>
            </div>
          </li>
        </ul>
      </div>
      <el-empty v-if="movieList.length === 0" :description="$t('No record found')"></el-empty>
      <el-pagination background layout="prev, pager, next" :total="totalNumber"></el-pagination>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from "@/components/header.vue"
import Footer from "@/components/footer.vue"
export default {
  components:{ Header, Footer },
  data(){
    return{
      classifyList:[this.$t('All'),this.$t('Action'),this.$t('Romance'),this.$t('Science fiction'),this.$t('Comedy'),this.$t('Thriller'),this.$t('Drama'),this.$t('War'),this.$t('Microfilm')],
      mvClassifyList:[this.$t('All'),this.$t('Pop music'),this.$t('Romance'),this.$t('Rock'),this.$t('Folk'),this.$t('Animemia'),this.$t('Piano'),this.$t('Vocal concert'),this.$t('Cartoon')],
      classifyActive:"0",
      mvClassifyActive:"0",
      movieList:[],
      totalNumber:0,
      selectType:"0"
    }
  },
  methods:{
    filterClassify(classify){
        let newClassify;
        switch(classify){
            case 'All': newClassify = "全部"; break;
            case 'Action': newClassify = "动作"; break;
            case 'Romance': newClassify = "爱情"; break;
            case 'Science fiction': newClassify = "科幻"; break;
            case 'Comedy': newClassify = "喜剧"; break;
            case 'Thriller': newClassify = "懸疑"; break;
            case 'Drama': newClassify = "剧情"; break;
            case 'War': newClassify = "战争"; break;
            case 'Microfilm': newClassify = "微电影"; break;
            case 'Pop music': newClassify = "流行音樂"; break;
            case 'Rock': newClassify = "搖滾"; break;
            case 'Folk': newClassify = "民族音樂"; break;
            case 'Animemia': newClassify = "純音樂"; break;
            case 'Piano': newClassify = "鋼琴曲"; break;
            case 'Vocal concert': newClassify = "演唱會"; break;
            case 'Cartoon': newClassify = "動畫"; break;
            default: newClassify = classify;
        }
        return newClassify;
    },
    getMovieList(page){
        let classify
        if(this.selectType === "0"){
          classify = this.filterClassify(this.classifyList[this.classifyActive])
        }else{
          classify = this.filterClassify(this.mvClassifyList[this.mvClassifyActive])
        }
        let classification = classify === "全部" ? "" : classify
        this.$fetch(this.$center_url+`/v1/movice/list?state=15&page=${page}&num=36&classification=${classification}&type=${this.selectType}`).then((res)=>{
            this.totalNumber = res.data.page_info.total
            this.movieList = res.data.data
        })
    },
    toDetail(item){
      this.$router.push("/MovieDetail?id="+item.id)
    },
  },
  created(){
    this.getMovieList(1)
  }
}
</script>
<style scoped lang="less">
@import url("./index.less");
</style>