<template>
    <div class="header">
        <div class="logo" @click="jump('/')">NFT {{$t('Movie')}}</div>
        <div class="right_menu">
            <div>
                <el-dropdown @command="changLang">
                    <span>
                        <i class="el-icon-s-comment"></i>{{$t('Language')}}
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="en">English</el-dropdown-item>
                        <el-dropdown-item command="cn">繁體中文</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
            <div>
                <el-popover placement="bottom" width="140" trigger="hover">
                    <div class="app_code" ref="appCodeUrl"></div>
                    <span slot="reference">
                        <i class="el-icon-download"></i>{{$t('Down load App')}}
                    </span>
                </el-popover>
            </div>
            <div @click="jump('/Message')"><i class="el-icon-bell"></i>{{$t('Messages')}}</div>
            <div @click="jump('/Subscribe')"><i class="el-icon-s-order"></i>{{$t('Subscribed')}}</div>
            <div @click="jump('/CreateNFT')"><i class="el-icon-plus"></i>NFT</div>
            <div @click="quit" v-if="account"><i class="el-icon-bottom-right"></i>{{$t('Log Out')}}</div>
            <div v-else @click="()=>{$router.push('/Login')}">
                 <i class="el-icon-bottom-right"></i>{{$t('Go to login')}}
            </div>
        </div>
    </div>
</template>
<script>
import QRCode from 'qrcodejs2'
export default {
    data(){
        return{
            account:""
        }
    },
    methods:{
        init(){
            let local = window.localStorage.getItem("pcLocalAccount")
            if(local){
                this.account = JSON.parse(local).nft_account
            }
        },
        jump(path){
            if(!this.account){
                this.$alert(this.$t('Need to log in to'), this.$t('Notice'),{confirmButtonText:this.$t('confirm')})
                return;
            }
            this.$router.push(path)
        },
        quit(){
            this.$confirm(this.$t('Do you want to log out of your wallet account?'), this.$t('Notice'), {confirmButtonText: this.$t('OK'), cancelButtonText: this.$t('Cancel'), type: 'warning'}).then(() => {
               window.localStorage.removeItem("pcLocalAccount")
               this.$router.replace("/login")
            }).catch(() => {})
        },
        changLang(e){
            window.localStorage.setItem("language",e)
            window.location.reload()
        },
        download(){
            this.$fetch(this.$center_url+"/v1/app?type=android").then((res)=>{
                this.appurl = res.data.downloadUrl;
                new QRCode(this.$refs.appCodeUrl, {
                    text: this.appurl,
                    width: 120,
                    height: 120,
                    colorDark: '#000000',
                    colorLight: '#ffffff',
                    correctLevel: QRCode.CorrectLevel.H
                })
            })
        },
    },
    created() {
        this.init()
        this.download()
    }
}
</script>
<style lang="less" scoped>
.header{
    height: 58px;
    width: 100%;
    background-image: linear-gradient(to bottom,#3c3c3c 0,#222 100%);
    padding: 14px 30px;
    .logo{
        color: #e9e9e9;
        font-size: 22px;
        float: left;
        cursor: pointer;
    }
    .right_menu{
        float: right;
        padding-top: 5px;
        font-size: 14px;
        div{
            display: inline-block;
            margin-left: 24px;
            color: #b8b8b8;
            cursor: pointer;
            transition: all .5s linear;
            i{
                margin-right: 4px;
            }
        }
        div:hover{
            color: #fff;
            
        }
        
    }
}
</style>