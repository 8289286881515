<template>
    <div class="footer">
        <div class="outer_box">
            <span @click="jump('/About')">{{$t('About Us')}}</span>
            <span @click="jump('/About')">{{$t('Contact Us')}}</span>
            <a href="http://www.stareast.io/nft_mmm_help.pdf">{{$t('direction for use')}}</a>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return{

        }
    },
    methods:{
        jump(path){
            this.$router.push(path)
        }
    }
}
</script>
<style lang="less" scoped>
.footer{
    height: 100px;
    margin-top: 40px;
    background-image: linear-gradient(to bottom,#3c3c3c 0,#222 100%);
    width: 100%;
    .outer_box{
        height: 100px;
        span,a{
            cursor: pointer;
            line-height: 100px;
            margin-right: 40px;
            color: #b8b8b8;
            transition: all .5s linear;
            font-size: 14px;
        }
        span:hover,a:hover{
            color: #fff;
        }
    }
}
a{
    text-decoration: none;
}
</style>